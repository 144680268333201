@import-normalize;
@font-face {
  font-family: 'HTNeon';
  src: url('/fonts/Flat-it - HTNeon.otf') format('opentype');
}

@font-face {
  font-family: 'BrownBold';
  src: url('/fonts/BrownPro-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'BrownRegular';
  src: url('/fonts/BrownPro-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'GothamBold';
  src: url('/fonts/GothamRnd-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'GothamBook';
  src: url('/fonts/GothamRnd-Book.otf') format('opentype');
}

@font-face {
  font-family: 'GothamLight';
  src: url('/fonts/GothamRnd-Light.otf') format('opentype');
}
@font-face {
  font-family: 'GothamMedium';
  src: url('/fonts/GothamRnd-Medium.otf') format('opentype');
}

a{
  -webkit-tap-highlight-color: transparent;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  background-color: #afcdd7;
  color: #0c307c;
  overflow-x: hidden;
}
html::-webkit-scrollbar {
  display: none;
}
*{
  margin: 0;
  padding: 0;
}

body {
  background: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

.pic {
  background: grey;
  border-radius: 3px;
  background-size: cover;
  background-position: center center;
  position: absolute;
  z-index: 2;
}

.menu{
  position: fixed;
  top: 28vh;
  left: 30px;
  font-family: 'HTNeon';
  font-size: 2.5vw;
  color: #0c307c;
}
.menuWrapper{
  position: relative;
  display: flex;
  z-index: 3;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.menuWrapper span{
  margin-bottom: 20px;
  padding-bottom: 4px;
  border-bottom: 2px solid;
  text-align: right;
}
.menuWrapper span:hover, .menuWrapper span.active{
  cursor: pointer;
  color: #f38f1c;
}

.logo{
  width: 24vw;
  left: 2vw;
  top: 10vh;
  z-index: 10;
  position: fixed;
  cursor: pointer;
}

.overlay{
  width: 100%;
  height:100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: padding 0.1s ease-in;
  transition: padding 0.1s ease-in;
  padding-left: 0px;
}

.meny{
  width: 46vw;
  z-index: 4;
  position: absolute;
  left: 50%;
  top: 10vh;
  font-size: 0.85em;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  padding: 20px;
  line-height: 2em;
  color: #0c307c;
}
.section {
  margin-top: 4vh;
  margin-bottom: 4vh;
  display: flex;
}
.meny .section:last-child {
  margin-bottom: 15vh
}
.meny .column {
  flex: 1 1;
}
.meny .column:first-child {
  margin-right: 2vw;
}
.meny .category {
  font-size: 1.4em;
  font-family: 'GothamMedium';
  border-bottom: 2px solid;
  padding-bottom: 20px;
  padding-top: 20px;
}
.meny .dish {
  position: relative;
  margin: 12px 0;
}
.meny .title {
  font-family: 'GothamMedium';
  padding-right: 6px;
  background: white;

}
.meny .desc {
  font-family: 'GothamLight';
  margin-right: 10px;
  z-index: 2!important;
  background: white;
  padding: 4px;
}
.meny .dots{
  font-family: 'GothamLight';
  position: absolute;
  right: 0;
  bottom: 2px;
  z-index: -1!important;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
}
.meny .price {
  font-family: 'GothamMedium';
  position: absolute;
  right: 0;
  bottom: 2px;
  background: white;
  z-index: 2!important;
  padding-left: 4px;

}
.phone{
  border-bottom: 3px solid #0c307c;
  padding-bottom: 2px;
}
.wine .title{
  display: block;
}
.wine .desc{
  display: inline-block;
  padding-left: 0;
}
.wine .desc2{
  padding-left: 0;
  display: inline-block;
}
.backStrip {
  position: fixed;
  top: 0;
  left: 50%;
  width: 50vw;
  height: 100%;
  background: white;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.backStrip .leftCurves {
  position: fixed;
  top: 0;
  left: -9px;
  height: 100%;
  width: 10px;
  background-image: url("/perfectright.png");
  background-size: contain;
}

.backStrip .rightCurves {
  position: fixed;
  top: 0;
  right: -9px;
  height: 100%;
  width: 10px;
  background-image: url("/perfectleft.png"); 
  background-size: contain;
}

.center {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
} 

h1 {
  text-align: center;
  font-family: 'GothamMedium';
  margin-bottom: 1em;
}
h2 {
  font-family: 'GothamMedium';
  margin-bottom: 1em;
}

.instagram{
  position: absolute;
  bottom: -60px;
  left: 55px;
  width: 34px;
}
.instagramMobile{
  z-index: 40;
  position: fixed;
  top: 9vw;
  right: 10vw;
  width: 5vw;
}
.instagramMobile:hover, .instagram:hover{
  cursor: pointer;
  fill: #f38f1c;
}
.main, .storre{
  white-space: pre-line;
  font-family: 'GothamMedium';
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  max-width: 720px;
  line-height: 2em;
  letter-spacing: 0.05em;
  width: 80%;
}

.main p, .storre p{
  font-size: 1em;
  margin-bottom: 2em;
  text-align: center;
  padding: 0 2vw;
}

.section p{
  font-family: 'GothamMedium';
}
.topcenter{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 80%;
}
.cover {
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: white;
  width: 79%;
  height: 12vh;
  -webkit-transition: height 0.1s ease-in, color 0.1s ease-in;
  transition: height 0.1s ease-in, color 0.1s ease-in;
  z-index: 9;
  display: none;
}
.cover.open {
  height: 25vh;
}
.mobileBurger{
  position: fixed;
  top: 0vh;
  left: 0vw;
  width: 24vw;
  height: 16vw;
  cursor: pointer;
  z-index: 30;
  display: none;
}
.mobileBurger img{
  margin-left: 10vw;
  margin-top: 4vw;
  width: 6vw;
}
.mobileMenu{
  opacity: 0;
  display: none;
  overflow: hidden;
  -webkit-transition: opacity 0.1s ease-in;
  transition: opacity 0.1s ease-in;
  position: fixed;
  top: 12vh;
  font-family: 'HTNeon';
  font-size: 4vw;
  flex-direction: column;
  z-index: 20;
  color: #0c307c;
  cursor: pointer;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.mobileMenu span{
  margin: 2vw 6vw;
  border-bottom: 2px solid;
  cursor: pointer;
}

.picsWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  opacity: 0;
  -webkit-transform: translateY(-100px);
          transform: translateY(-100px);
}
.picsWrapper.show{
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px)
}

@media screen and (max-width: 992px) {
  .instagram{
    display: none;
  }
  .main img{
    width: unset;
    max-width: 80vw;
    height: unset;
  }
  .picsWrapper img{
    position: unset;
    width:100vw!important;
  }
  .section {
    flex-direction: column;
  }
  .backStrip{
    width: 90%;
  }
  .cover{
    display: block;
  }
  .logo{
    width: 50vw;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: 1.5vh!important;
    position: fixed;
    cursor: pointer;
  }
  .cover.start {
    background-color: #fab8ac;
    width: 100%;
  }
  .menu{
    display: none;
  }
  .mobileMenu{
    display: flex;
  }
  .mobileMenu.open{
    opacity: 1;
  }
  .overlay.open{
    padding-top: 25vw;
  }
  .mobileBurger{
    display: block;
  }
  .meny .column:first-child {
    margin-right: 0px;
  }
  .meny{
    width: 75vw;
  }
  .hideMobile{
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .menu{
    font-size: 24px;
  }
}

a {
  color: #0c307c;
  text-decoration: none;
}
